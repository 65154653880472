import Immutable, { List, Map } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import { appActionTypes } from '../../../shared/stores/app_actionTypes';
import { APPLICATION, EQUIPMENT, RETRO } from '../config_values';


const BLUE = '#1B3D9F';
// layer types
// const APPLICATION = 'application';
// const EQUIPMENT = 'equipment';

// view types
const PATTERN = 'pattern';
const PREFORMANCE = 'preformance';

// layer id
// dataRange = {dates: [], key: 'mtd'}
const NEW_LAYER_ID = null;
// const DATE_RANGE_DEFAULT = null;
const JOB_ID_DEFAULT = null;
const EQUIPMENT_ID_DEFAULT = null;
// const JOB_ID_DEFAULT = Map({});
// const EQUIPMENT_ID_DEFAULT = Map({});

const EMPTY_FEATURE_COLLECTION = {type: 'FeatureCollection', features: []};
const initalApplicationLayer = Immutable.fromJS({
    color: BLUE,
    isHidden: false,
    dateRange:{dates: [], dropdownValue: ''}, 
    jobID: JOB_ID_DEFAULT,
    equipmentID: EQUIPMENT_ID_DEFAULT,
    view: PATTERN,
    // view: 'eagle_eye',
    featureCollection: EMPTY_FEATURE_COLLECTION,
    // color: '',
    isShowingEagleEye: false,
    isShowingHandwork: true,
    isShowingComments: true,
    layerType: APPLICATION,
    lineType: null,
    paintColor: null,
    lineWidth: null,
    payItem: null,
    materialType: null,
    isOpacity: true,
    opacityNum: 0.3,
    thresholdColors: [],
});

const initalRetroLayer = Immutable.fromJS({
    isHidden: false,
    dateRange:{dates: [], dropdownValue: ''}, 
    region: '',
    line_type: '',
    job: '',
    route: '',
    direction: '',
    avaliableDirections: {},
    layerType: RETRO,
    name: '',
    thresholdColors: [],
});

const initialState = Immutable.fromJS({
    isCompareWindowOpen: false,
    compareWindow: [null],
    compareLayer: null,
    mapDataLoading: false,
    isNewLayer: false,
    isFiltersMenuOpen: false,
    filterType: APPLICATION,
    mapType: APPLICATION,
    layers: [],
    mapStyle: 'dark-v10',
    selectedLayer: null,
    isMapDataLoading: false,
    mileMarkersLayer: false,
    createReport: {reportID: null, layerID: null},
    [EQUIPMENT]: {
        allEquipment: [],
        selectedRow: null,
        // selectedEquipment: null,
    },
    [RETRO]: {
        job: {},
        lineType: '',
        dateFrom: '',
        dateTo: '',

    },


});

const SET_RETRO_FILTERS = 'SET_RETRO_FILTERS';
export const setRetroFilters = (
    newJob='',
    newRoute='',
    newRegion='',
    newLineType='',
    newDirection='',
    newDateRange,
    newAvaliableLineTypes=List([]),
    newAvaliableDirections=Map({}),
    retroLayerID=NEW_LAYER_ID,
    retroName=null,
    thresholdColors,
    _isOpacity,
    _opacityNum,
) => ({
    type: SET_RETRO_FILTERS,
    retroLayerID,
    newRoute,
    newJob,
    newRegion,
    newLineType,
    newDateRange,
    newAvaliableLineTypes,
    newAvaliableDirections,
    newDirection,
    retroName,
    thresholdColors,
    _isOpacity,
    _opacityNum,
});

const SELECT_COMPARE_LAYER = 'SELECT_COMPARE_LAYER';
export const selectCompareLayer = (compareLayer) => {
    return ({
        type: SELECT_COMPARE_LAYER,
        compareLayer,
    });
};
const TOGGLE_COMPARE_WINDOW = 'TOGGLE_COMPARE_WINDOW';
export const toggleCompareWindow = (isCompareWindowOpen=false) => {
    return ({
        type: TOGGLE_COMPARE_WINDOW,
        isCompareWindowOpen,
    });
};

const TRIGGER_SAVING_LAYERS = 'TRIGGER_SAVING_LAYERS';
export const triggerSavingLayers = (_layersHaveUpdated=false) => {
    return ({
        type: TRIGGER_SAVING_LAYERS,
        _layersHaveUpdated: false
    });
};

const SET_FILTER_TYPE = 'SET_FILTER_TYPE';
export const setFilterType = (filterType) => {
    return ({
        type: SET_FILTER_TYPE,
        filterType,
    });
};

const ADD_NEW_LAYER = 'ADD_NEW_LAYER';
export const addNewLayer = () => {
    return ({
        type: ADD_NEW_LAYER
    });
};
const EDIT_LAYER = 'EDIT_LAYER';
export const editLayer = (_layerIndex, _layerType) => {
    return ({
        type: EDIT_LAYER,
        _layerIndex,
        _layerType,

    });
};

const TOGGLE_FILTERS_MENU = 'TOGGLE_FILTERS_MENU';
export const toggleFiltersMenu = () => {
    return ({
        type: TOGGLE_FILTERS_MENU

    });
};
const SELECT_LAYER = 'SELECT_LAYER';
export const selecteLayer = (selecteLayerID) => {
    return ({
        type: SELECT_LAYER,
        selecteLayerID,

    });
};

const TOGGLE_VISIBILITY = 'TOGGLE_VISIBILITY';
export const toggleLayerVisibility = (visibilityLayerID) => {
    return ({
        type: TOGGLE_VISIBILITY,
        visibilityLayerID

    });
};

// New filters menu ^^

const TOGGLE_HANDWORK = 'TOGGLE_HANDWORK';
export const toggleHandwork = () => {
    return ({
        type: TOGGLE_HANDWORK,
    });
};
const TOGGLE_EAGLE_EYE = 'TOGGLE_EAGLE_EYE';
export const toggleEagleEye = () => {
    return ({
        type: TOGGLE_EAGLE_EYE,
    });
};

// retro
const SET_FILENAME = 'SET_FILENAME';
export const setFilename = (filename) => {
    return ({
        type: SET_FILENAME,
        filename
    });
};

const SET_REPORT_ID = 'SET_REPORT_ID';
export const setReportID = (reportID, exportLayerID) => {
    return ({
        type: SET_REPORT_ID,
        reportID,
        exportLayerID
    });
};

const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const setSelectedCategory = (category, cat_layerID, isSecondWindow) => {
    return ({
        type: SET_SELECTED_CATEGORY,
        category,
        cat_layerID,
        isSecondWindow,
    });
};
const SET_LAYERS = 'SET_LAYERS';
export const setLayers = (allLayers, _mapStyle) => {
    return ({
        type: SET_LAYERS,
        allLayers,
        _mapStyle,
    });
};

const TOGGLE_MILE_MARKERS = 'TOGGLE_MILE_MARKERS';
export const toggleMileMarkers = () => {
    return ({
        type: TOGGLE_MILE_MARKERS
    });
};
const TOGGLE_COMMENTS = 'TOGGLE_COMMENTS';
export const toggleComments = () => {
    return ({
        type: TOGGLE_COMMENTS
    });
};

const UPDATE_OR_ADD_LAYER = 'UPDATE_OR_ADD_LAYER';
export const updateOrAddLayer = ({
    layerType=APPLICATION,
    id=NEW_LAYER_ID,
    jobID=JOB_ID_DEFAULT,
    equipmentID=EQUIPMENT_ID_DEFAULT,
    dateRange,
    // dateRange=DATE_RANGE_DEFAULT,
    featureCollection={type: 'FeatureCollection', features: []},
    view=PATTERN, // there will always be view selected
    isEagleEye=false,
    isHandwork=false,
    isComments=false,
    lineType=null,
    lineWidth=null,
    paintColor=null,
    materialType=null,
    name='',
    batch=null,
    isOpacity=true,
    opacityNum,
    color=BLUE,
    applicationThresholdColors,
}) => {
    return {
        type: UPDATE_OR_ADD_LAYER,
        id,
        jobID,
        equipmentID,
        dateRange,
        view,
        isEagleEye,
        isHandwork,
        isComments,
        layerType,
        lineType,
        lineWidth,
        paintColor,
        materialType,
        name,
        batch,
        isOpacity,
        opacityNum,
        color,
        applicationThresholdColors
        // featureCollection,
    };
}

const SET_JOB_TABLE_SELECTION = 'SET_JOB_TABLE_SELECTION';
export const setJobTableSelection = (jobID, dateRange) => {
    return ({
        type: SET_JOB_TABLE_SELECTION,
        jobID,
        dateRange,
        id: 'JOB_PAGE',
    });
};

const CHANGE_SELECTED_LAYER = 'CHANGE_SELECTED_LAYER';
export const changeSelectedLayer = (layerID) => {
    return ({
        type: CHANGE_SELECTED_LAYER,
        layerID
    });
};

const UPDATE_MAP_STYLE = 'UPDATE_MAP_STYLE';
export const updateMapStyle = (mapStyle) => {
    return ({
        type: UPDATE_MAP_STYLE,
        mapStyle
    });
};

const DELETE_LAYER = 'DELETE_LAYER';
export const deleteLayer = (del_layerID) => {
    return ({
        type: DELETE_LAYER,
        del_layerID
    });
};

const SELECT_SEGMENT = 'SELECT_SEGMENT';
export const selectSegment = (segmentID, segmentIndex, seg_mapLayerID) => {
    return ({
        type: SELECT_SEGMENT,
        segmentID,
        segmentIndex,
        seg_mapLayerID,
    });
};

const CLEAR_SEGMENT = 'CLEAR_SEGMENT';
export const clearSegment = (clearLayerID) => {
    return ({
        type: CLEAR_SEGMENT,
        clearLayerID,
    });
};

const SET_FEATURE_COLLECTION = 'SET_FEATURE_COLLECTION';
export const setFeatureCollection = (
    featureCollection,
    fc_mapLayerID,
    fc_layerIndex,

) => {
    return ({
        type: SET_FEATURE_COLLECTION,
        featureCollection,
        fc_mapLayerID,
        fc_layerIndex,
    });
};

const SELECT_JOB = 'SELECT_JOB';
export const selectJob = (job, job_mapLayerID) => {
    return ({
        type: SELECT_JOB,
        job,
        job_mapLayerID
    });
};

const SET_LAYER_LOADING = 'SET_LAYER_LOADING';
export const setLayerLoading = (loadinglayerID, loadingMapID, loading) => {
    return ({
        type: SET_LAYER_LOADING,
        loadinglayerID,
        loadingMapID,
        loading,
    });
};

const SET_EQUIPMENT_FROM_DASHBOARD = 'SET_EQUIPMENT_FROM_DASHBOARD';
export const setEquipmentFromDashboard = (truckID) => {
    return ({
        type: SET_EQUIPMENT_FROM_DASHBOARD,
        truckID
    });
};

const SET_ALL_EQUIPMENT = 'SET_ALL_EQUIPMENT';
export const setAllEquipment = (allEquipment) => {
    return ({
        type: SET_ALL_EQUIPMENT,
        allEquipment
    });
};

const SET_SELECTED_EQUIPMENT = 'SET_SELECTED_EQUIPMENT';
// equipmentID is just its index id which will match the row index in the table
export const setSelectedEquipment = (rowID) => {
    return ({
        type: SET_SELECTED_EQUIPMENT,
        rowID
    });
};


const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const updateEquipment = (equipmentUpdate) => {
    return ({
        type: UPDATE_EQUIPMENT,
        equipmentUpdate

    });
};

const HANDLE_DROPDOWN_SELECT = 'HANDLE_DROPDOWN_SELECT';
export const handleDropdownSelect = (dropdownSelection) => {
    return ({
        type: HANDLE_DROPDOWN_SELECT,
        dropdownSelection

    });
};

const HANDLE_MAP_TYPE_CHANGE = 'HANDLE_MAP_TYPE_CHANGE';
export const handleMapTypeChange = (mapType) => {
    return ({
        type: HANDLE_MAP_TYPE_CHANGE,
        mapType
    });
};



let layerColors = [
    '#009FD7',
    '#6c27B6',
    '#006CDB',
    '#6AB9A8',
    '#E977DD',
];

const skyviewReducer = (state=initialState, action) => {
    const currentAmountOfLayers = state.get('layers', List([])).size
    switch(action.type){

        case SELECT_COMPARE_LAYER:
            const {compareLayer} = action;
            const _compareLayer = state.getIn(['layers', compareLayer], null);
            
            return state
                .setIn(['compareWindow', 0], _compareLayer)
                .set('compareLayer', compareLayer);

        case TOGGLE_COMPARE_WINDOW:
            const {isCompareWindowOpen} = action;
            let _selectedLayer = null;
            if (isCompareWindowOpen){
                _selectedLayer = state.getIn(['layers', state.get('selectedLayer', null)], null);
            }
            return state
                .setIn(['compareWindow', 0], _selectedLayer)
                .set('isCompareWindowOpen', isCompareWindowOpen);

        case TRIGGER_SAVING_LAYERS:
            const {_layersHaveUpdated} = action;
            return state.set('layersHaveUpdated', _layersHaveUpdated);

        case SET_LAYERS:
            let { allLayers, _mapStyle } = action;
            const _allLayers = allLayers.map(layer => layer.set('isMapDataLoading', true));            
            let updatedState = state.set('layers', _allLayers);
            if (allLayers.size > 0){
                updatedState = updatedState.set('mapDataLoading', true);
            }
            updatedState = updatedState.set('mapStyle', _mapStyle);
            return updatedState

        case SET_FILTER_TYPE:
            const {filterType} = action;
            return state.set('filterType', filterType);

        case TOGGLE_VISIBILITY:
            const {visibilityLayerID} = action;
            const updatedVisibility = !state.getIn(
                ['layers', visibilityLayerID, 'isHidden'], false
            );
            return state
                .set('layersHaveUpdated', true)
                .setIn(['layers', visibilityLayerID, 'isHidden'], updatedVisibility);


        case ADD_NEW_LAYER:
            return state
                .set('isFiltersMenuOpen', true)
                .set('filterType', APPLICATION)
                .set('isNewLayer', true);

        case TOGGLE_FILTERS_MENU:
            const currentFilterState = state.get('isFiltersMenuOpen', false);
            return state
                .set('isNewLayer', false)
                .set('isFiltersMenuOpen', !currentFilterState);

        case EDIT_LAYER:
            const { _layerType, _layerIndex } = action;
            return state
                .set('isNewLayer', false)
                .set('filterType', _layerType)
                .set('isFiltersMenuOpen', true)
                .set('selectedLayer', _layerIndex);


        case SELECT_LAYER:
            const { selecteLayerID } = action;
            return state.set('selectedLayer', selecteLayerID);

        case CHANGE_SELECTED_LAYER:
            const { layerID } = action;
            return state
                .set('selectedLayer', layerID)

        case SET_REPORT_ID:
            const { reportID, exportLayerID } = action;
            return state
                .setIn(['createReport', 'reportID'], reportID)
                .setIn(['createReport', 'layerID'], exportLayerID)

        case HANDLE_MAP_TYPE_CHANGE:
            const { mapType} = action;
            return state
                .set('mapType', mapType)
                .set('layersHaveUpdated', true);

        case SET_LAYER_LOADING:
            const {loadinglayerID, loadingMapID, loading} = action;
            return state
                .set('mapDataLaoding', loading)
                .setIn(['layers', loadinglayerID, 'isMapDataLoading'], loading)
                .set(loadingMapID, {type: 'FeatureCollection', features: []})

        case SET_FEATURE_COLLECTION:
            const { featureCollection, fc_mapLayerID,  fc_layerIndex} = action;
            return state
                .set(fc_mapLayerID, featureCollection)
                .set('mapDataLoading', false)
                .setIn(['layers', fc_layerIndex, 'isMapDataLoading'], false);

        case CLEAR_SEGMENT:
            const clearLayerID = state.get('selectedLayer', '');
            return state
                .deleteIn(['layers', clearLayerID, 'segmentID'])
                .deleteIn(['layers', clearLayerID, 'segmentIndex'])
                .set('layersHaveUpdated', true)
            // .setIn(['layers',clearLayerID, 'category'], false);

        case appActionTypes["app/changeUserOrg"]:
            // const layers = state.getIn(['layers'], List([]));
            // if (layers.size === 0) return state;

            // // remove all the layer colors
            // layers.forEach(layer => {
            //     const color = layer.get('color');
            //     layerColors.unshift(color);
            // });

            return state.set('layers', List([]));


        case TOGGLE_MILE_MARKERS:
            const newMileMarkerLayer = state.get('mileMarkersLayer', false);
            return state.set('mileMarkersLayer', !newMileMarkerLayer);

        case UPDATE_MAP_STYLE:
            const mapStyle = action.mapStyle;
            return state
                .set('mapStyle', mapStyle)
                .set('layersHaveUpdated', true)

        case SELECT_SEGMENT: 
            const {
                segmentID,
                segmentIndex,
                seg_mapLayerID
            } = action;
            const seg_layerID = state
                .get('layers', List([]))
                .findIndex(_layer => {
                    return seg_mapLayerID === _layer.get('mapLayerID', '');
                });

            if (seg_layerID === -1) return state;

            let seg_newState = state
                .setIn(['layers', seg_layerID, 'segmentID'], segmentID)
                .setIn(['layers', seg_layerID, 'segmentIndex'], segmentIndex)

            const currentlySelectedLayer = state.get('selectedLayer', null);
            if (seg_layerID !== currentlySelectedLayer){
                seg_newState = seg_newState.set('selectedLayer', seg_layerID);
            }

            return state
                .merge(seg_newState)
                .set('layersHaveUpdated', true);


        case SET_RETRO_FILTERS:
            let {
                newJob,
                retroLayerID,
                newRoute,
                newRegion,
                newDateRange,
                newLineType,
                newDirection,
                retroName,
                thresholdColors,
                _isOpacity,
                _opacityNum,
            } = action;

            let updatedRetroLayer = state.getIn(
                ['layers', retroLayerID], initalRetroLayer
            );
            console.log('newJob store', newJob);
            if (retroLayerID === null){
                retroLayerID = currentAmountOfLayers;
                const retroMapID = uuidv4();
                updatedRetroLayer = updatedRetroLayer.set('mapLayerID', retroMapID);
            }
            updatedRetroLayer = updatedRetroLayer
            // .set('isMapDataLoading', true)
                .set('dateRange', newDateRange)
                .set('route', newRoute)
                .set('region', newRegion)
                .set('line_type', newLineType)
                .set('direction', newDirection)
                .set('name', retroName)
                .set('thresholdColors', thresholdColors)
                .set('isOpacity', _isOpacity)
                .set('opacityNum', _opacityNum)
                .set('job', newJob)


            console.log('updatedRetroLayer', updatedRetroLayer.toJS());

            return state
                .set('isNewLayer', false)
                .set('selectedLayer', retroLayerID)
                .set('isFiltersMenuOpen', false)
                .set('mapDataLoading', true)
                .set('filterType', APPLICATION)
                .set('layersHaveUpdated', true)
                .setIn(['layers', retroLayerID], updatedRetroLayer);

        case SET_JOB_TABLE_SELECTION:
        case UPDATE_OR_ADD_LAYER: 
            let id = action.id;
            let _layerID = id;
            let updatedLayer;

            let { 
                // id,
                jobID,
                equipmentID=EQUIPMENT_ID_DEFAULT,
                view=PATTERN,
                dateRange,
                isEagleEye=false,
                isComments=false,
                isHandwork=false,
                lineType=null,
                lineWidth=null,
                paintColor=null,
                materialType=null,
                name=null,
                batch=null,
                isOpacity=true,
                opacityNum=0.3,
                color=BLUE,
                applicationThresholdColors=List([])
            } = action;


            // let _layerID = id;
            updatedLayer = state.getIn(
                ['layers', _layerID], initalApplicationLayer
            );
            if (_layerID === null || _layerID === 'JOB_PAGE'){
                _layerID = currentAmountOfLayers;
                if (_layerID === 5){
                    _layerID = 4;
                } 
                const maplayerID = uuidv4();
                // const newLayerColor = layerColors.shift();
                updatedLayer = updatedLayer.set('mapLayerID', maplayerID);
            };



            updatedLayer = updatedLayer.set('materialType', materialType);
            updatedLayer = updatedLayer.set('batch', batch);
            updatedLayer = updatedLayer.set('name', name);
            updatedLayer = updatedLayer.set('lineWidth', lineWidth);
            updatedLayer = updatedLayer.set('paintColor', paintColor);
            updatedLayer = updatedLayer.set('lineType', lineType);
            updatedLayer = updatedLayer.set('dateRange', dateRange);
            updatedLayer = updatedLayer.set('jobID', jobID);
            updatedLayer = updatedLayer.set('color', color);
            updatedLayer = updatedLayer.set('equipmentID', equipmentID);
            updatedLayer = updatedLayer.set('view', view);
            updatedLayer = updatedLayer.set('isShowingHandwork', isHandwork);
            updatedLayer = updatedLayer.set('isShowingComments', isComments);
            updatedLayer = updatedLayer.set('isOpacity', isOpacity);
            updatedLayer = updatedLayer.set('opacityNum', opacityNum);
            updatedLayer = updatedLayer.set('thresholdColors', applicationThresholdColors);
            updatedLayer = updatedLayer.delete('segment');
            const eagleEye = equipmentID === null 
                ? false
                :  equipmentID.get('eagle_eye', false);
            const isEagleEyeCurrentlySelected = updatedLayer.get('isShowingEagleEye', false);
            if ((isEagleEyeCurrentlySelected && !eagleEye) || !isEagleEye){
                updatedLayer = updatedLayer.set('isShowingEagleEye', false);
            } else if (isEagleEye && eagleEye){
                updatedLayer = updatedLayer.set('isShowingEagleEye', isEagleEye);
            }


            return state
                .set('layersHaveUpdated', true)
                .set('mapDataLoading', true)
                .set('isNewLayer', false)
                .set('selectedLayer', _layerID)
                .set('isFiltersMenuOpen', false)
                .set('filterType', APPLICATION)
                .setIn(['layers', _layerID], updatedLayer);


        case DELETE_LAYER:
            const {del_layerID } = action;
            let del_state = state.deleteIn(['layers', del_layerID]);

            const _compareLayerID = state.get('compareLayer', null);
            if (del_layerID === _compareLayerID) {
                del_state = del_state
                    .set('compareWindow', List([null]))
                    .set('compareLayer', null)
                    .set('isCompareWindowOpen', false)
            }
            const del_selectedLayer = state.get('selectedLayer', null);
            const amountOfLayers = del_state.get('layers', List([])).size;

            let newSelectedLayer = del_layerID !== del_selectedLayer 
                && del_layerID > del_selectedLayer
                ? del_selectedLayer 
                : del_layerID !== del_selectedLayer && del_layerID < del_selectedLayer
                ? del_selectedLayer -1
                : amountOfLayers > 0
                ? 0
                : null


            del_state = del_state
                .set('selectedLayer', newSelectedLayer)
                .set('layersHaveUpdated', true)

            return state.merge(del_state);

            // Equipment 
        case HANDLE_DROPDOWN_SELECT:
            const { dropdownSelection } = action;
            if (dropdownSelection === null) return state.setIn([EQUIPMENT, 'selectedRow'], null);
            return state.setIn([EQUIPMENT, 'selectedRow'], dropdownSelection);

        case UPDATE_EQUIPMENT:
            let equipmentUpdate = action.equipmentUpdate;

            let _allEquipment = state.getIn([EQUIPMENT, 'allEquipment'], Map({}));
            const _equipmentID  = equipmentUpdate.get('serial_number', 'not_found');

            const isExistingEquipment = _allEquipment.get(_equipmentID, false);
            if (!isExistingEquipment) return state;

            // checking for bad coords and dropping them if we find them.
            const coords = equipmentUpdate.getIn(['last_location', 'coordinates'], List([]));

            // if both coords are 0 0 then those are bad and we want to
            // delete them
            if (coords.get(0, 0) === 0 && coords.get(1, 0) === 0) {
                equipmentUpdate = equipmentUpdate.delete('last_location');
            }

            const updatedEquipment = _allEquipment.get(_equipmentID).merge(equipmentUpdate);
            _allEquipment = _allEquipment.set(_equipmentID, updatedEquipment);


            return state.setIn([EQUIPMENT, 'allEquipment'], _allEquipment);

        case SET_EQUIPMENT_FROM_DASHBOARD:
            let { truckID } = action;
            return state
                .setIn([EQUIPMENT, 'selectedRow'], truckID)
                .set('mapType', EQUIPMENT);

        case SET_SELECTED_EQUIPMENT:
            let { rowID } = action;
            const currentlySelectedRow = state.getIn([EQUIPMENT, 'selectedRow'], null);
            rowID = currentlySelectedRow === rowID ? null : rowID;
            return state.setIn([EQUIPMENT, 'selectedRow'], rowID);

        case SET_SELECTED_CATEGORY:
            const {category, cat_layerID, isSecondWindow} = action;
            let CAT_KEY = ['layers', cat_layerID, 'category'];
            if (isSecondWindow){
                CAT_KEY = ['compareWindow', 0, 'category'];
            }
            return state.setIn(CAT_KEY, category);

        case SELECT_JOB:
            const { job, job_mapLayerID} = action;

            const loadingJobMapID = state.getIn(['layers', job_mapLayerID, 'mapLayerID'], '');
            return state
                .set('layersHaveUpdated', true)
                .setIn(['layers', job_mapLayerID, 'jobID'], job)
                .setIn(['layers', job_mapLayerID, 'isMapDataLoading'], true)
                .set('selectedLayer', job_mapLayerID)
                .set(loadingJobMapID, EMPTY_FEATURE_COLLECTION);

        case SET_ALL_EQUIPMENT:
            const {allEquipment} = action;
            let _allEquipmentObjs = Map({});
            allEquipment.forEach(eq => {
                const eqSerialNumber = eq.get('serial_number', null);
                if (eqSerialNumber === null) return;

                _allEquipmentObjs = _allEquipmentObjs.set(eqSerialNumber, eq);
            });
            return state.setIn([EQUIPMENT, 'allEquipment'], _allEquipmentObjs);
            // return state.setIn([EQUIPMENT, 'allEquipment'], temp_allEquipment);

        default:
            return state;
    }
};


export default skyviewReducer;
