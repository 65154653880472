import Immutable, { List, Map } from 'immutable';

const ADD_RETRO_JOB = 'ADD_RETRO_JOB';
export const addRetroJob = (newRetroJob=Map({})) => ({
    type: ADD_RETRO_JOB,
    newRetroJob
});

const SET_ALL_CURRENT_JOBS = 'SET_ALL_CURRENT_JOBS';
export const setAllCurrentJobs = (allCurrentJobs=List([])) => ({
    type: SET_ALL_CURRENT_JOBS,
    allCurrentJobs,
});

const SET_ALL_UPCOMING_JOBS = 'SET_ALL_UPCOMING_JOBS';
export const setAllUpcomingJobs = (allUpcomingJobs=List([])) => ({
    type: SET_ALL_UPCOMING_JOBS,
    allUpcomingJobs,
});

const SET_ALL_RETRO_JOBS = 'SET_ALL_RETRO_JOBS';
export const setAllRetroJobs = (allRetroJobs=List([])) => ({
    type: SET_ALL_RETRO_JOBS,
    allRetroJobs,
});

const SET_SHARE_JOB_MODAL = 'SET_SHARE_JOB_MODAL';
export const setShareJobModal = (isShareJobModalOpen, jobToShare=Map({}), jobToShareIndex=null) => ({
    type: SET_SHARE_JOB_MODAL,
    isShareJobModalOpen,
    jobToShare,
    jobToShareIndex,

});

const ADD_NEW_UPCOMING_JOB = 'ADD_NEW_UPCOMING_JOB';
export const addNewJob = (newUpcomingJob) => ({
    type: ADD_NEW_UPCOMING_JOB,
    newUpcomingJob,

});

const SET_SPLIT_JOB_MODAL = 'SET_SPLIT_JOB_MODAL';
export const setSplitJobModal = (isSplitJobModalOpen, jobToSplit=Map({})) => ({
    type: SET_SPLIT_JOB_MODAL,
    isSplitJobModalOpen,
    jobToSplit,
});

const DELETE_RETRO_JOB = 'DELETE_RETRO_JOB';
export const deleteRetroJob = (retroJobIndex) => ({
    type: DELETE_RETRO_JOB,
    retroJobIndex,
});

const HANDLE_JOB_SELECT = 'HANDLE_JOB_SELECT';
export const handleJobSelect = (selectedJob) => ({
    type: HANDLE_JOB_SELECT,
    selectedJob,
});

const CLEAR_SELECTED_JOBS = 'CLEAR_SELECTED_JOBS';
export const clearSelectedJobs = () => ({
    type: CLEAR_SELECTED_JOBS,
});

const UPDATE_JOB_NAME = 'UPDATE_JOB_NAME';
export const updateJobName = (newName, jobIndex) => ({
    type: UPDATE_JOB_NAME,
    newName,
    jobIndex,
});

const UPDATE_JOB_SHARE = 'UPDATE_JOB_SHARE';
export const updateJobShare = (jobShareIndex=null) => ({
    type: UPDATE_JOB_SHARE,
    jobShareIndex
});

const REMOVE_UPCOMING_JOB = 'REMOVE_UPCOMING_JOB';
export const removeUpcomingJob = (upcomingJobIndex) => ({
    type: REMOVE_UPCOMING_JOB,
    upcomingJobIndex
});



const initialState =  Immutable.fromJS({
    allCurrentJobs: [],
    allRetroJobs: [],
    allUpcomingJobs: [],
    isShareJobModalOpen: false,
    isSplitJobModalOpen: false,
    exportMenuAnchorEl: null,
    jobToSplit: {},
    selectedJobs: {},
    jobToShareIndex: null,

});

const jobsPageReducer = (state=initialState, action) => {
    switch(action.type){

        case ADD_RETRO_JOB:
            const { newRetroJob } = action;
            let updatedRetroJobsList = state.get('allRetroJobs', List([]));
            updatedRetroJobsList = updatedRetroJobsList.unshift(newRetroJob);
            return state.set('allRetroJobs', updatedRetroJobsList);

        case UPDATE_JOB_SHARE:
            // this is a bit hacky but at the time of job share, we do not have
            // the job index at that component so we store it in redux but when
            // we unshare a job, we do have it so we are passing it to the
            // action creator. below we are determining which one it is;
            // also we know that if it the value is from state, the job was just
            // shared and if the value was from the actor creator, it was the
            // last share on that job and we need to remove the is shared true
            // state. .
            let { jobShareIndex } = action;
            const updatedIsShared = jobShareIndex === null;
            jobShareIndex = jobShareIndex !== null ? jobShareIndex : state.get('jobToShareIndex', null);
            return state
                .set('jobToShare', Map({}))
                .set('jobToShareIndex', null)
                .set('isShareJobModalOpen', false)
                .setIn(['allCurrentJobs', jobShareIndex, 'is_shared'], updatedIsShared);

        case UPDATE_JOB_NAME:
            const { jobIndex, newName } = action;
            return state.setIn(['allCurrentJobs', jobIndex, 'display_name'], newName);

        case CLEAR_SELECTED_JOBS:
            return state.set('selectedJobs', Map({}));


        case HANDLE_JOB_SELECT:
            const { selectedJob } = action;
            // this comes from the table and the object isn't immutbale.
            const jobName = selectedJob?.name || '';
            const jobID = selectedJob?.id || selectedJob?._id || '';
            let updatedSelectedJobs = state.get('selectedJobs', Map({}));
            if (updatedSelectedJobs.has(jobID)){
                updatedSelectedJobs = updatedSelectedJobs.delete(jobID);
            } else {
                updatedSelectedJobs = updatedSelectedJobs.set(jobID, jobName);
            }

            return state.set('selectedJobs', updatedSelectedJobs);

        case REMOVE_UPCOMING_JOB:
            const { upcomingJobIndex } = action;
            let removedUpcomingJobState = state.deleteIn(['allUpcomingJobs', upcomingJobIndex]);
            return removedUpcomingJobState

        case ADD_NEW_UPCOMING_JOB:
            const { newUpcomingJob } = action;
            let _updatedeUpcomingJobs = state.get('allUpcomingJobs', List([]));
            _updatedeUpcomingJobs = _updatedeUpcomingJobs.unshift(newUpcomingJob);
            return state.set('allUpcomingJobs', _updatedeUpcomingJobs);

        case SET_SPLIT_JOB_MODAL:
            const { isSplitJobModalOpen, jobToSplit } = action;
            return state
                .set('jobToSplit', jobToSplit)
                .set('isSplitJobModalOpen', isSplitJobModalOpen);

        case SET_SHARE_JOB_MODAL:
            const { isShareJobModalOpen, jobToShare, jobToShareIndex} = action;
            return state
                .set('isShareJobModalOpen', isShareJobModalOpen)
                .set('jobToShare', jobToShare)
                .set('jobToShareIndex', jobToShareIndex);


        case SET_ALL_UPCOMING_JOBS:
            const { allUpcomingJobs } = action;
            const _allUpcomingJobs = allUpcomingJobs.get('jobs', List([]));
            return state.set('allUpcomingJobs', _allUpcomingJobs);

        case SET_ALL_CURRENT_JOBS:
            const { allCurrentJobs } = action;
            return state.set('allCurrentJobs', allCurrentJobs.get('jobs', List([])));

        case SET_ALL_RETRO_JOBS:
            const { allRetroJobs } = action;
            return state.set('allRetroJobs', allRetroJobs);

        case DELETE_RETRO_JOB:
            const { retroJobIndex } = action;
            return state.deleteIn(['allRetroJobs', retroJobIndex]);
    default: 
        return state;
    };
};

export default jobsPageReducer;
