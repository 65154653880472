import { useState } from 'react';
import { List } from "immutable";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import specRiteLogo from "../images/logo-for-auth0.png";
import Box from '@mui/material/Box';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';



export default function MobileNav(props){

    const location = useLocation();
    const currentPage = location && location.pathname.split("/")[1];

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const pages = useSelector((state) =>
        state.app.getIn(["user", "pages"], List([]))
    );

    return (
        <Box
            sx={{
                borderBottom: 'solid lightgrey 2px',
                display: 'flex',
                // backgroundColor: 'rgba(239, 241, 242, 1)',
                width: '100%',
                // position: 'fixed',
                position: 'sticky',
                top: 0,
                background: 'white',
                zIndex: 1000,
            }}
        >
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                // slotProps={{
                //     paper: {
                //         style: {
                //             maxHeight: ITEM_HEIGHT * 4.5,
                //             width: '20ch',
                //         },
                //     },
                // }}
            >
                {pages.map((page) => {
                    let link = page.get("link", "");
                    const pageID = page.get("_id", "");
                    const label = page.get("label", "");

                    return (
                        <MenuItem 
                            key={pageID} 
                            onClick={handleClose}
                            sx={{

                                color:
                                pageID === currentPage
                                    ? "rgb(50, 108, 133)"
                                    : "rgba(0, 0, 0, 0.6)",
                                bgcolor:
                                pageID === currentPage
                                    ? "rgba(50, 108, 133, 0.3)"
                                    : "background.default"
                            }}
                        >
                            <Link
                                to={link}
                                style={{
                                    bottom: 4,
                                    width: "100%",
                                color:
                                pageID === currentPage
                                    ? "rgb(50, 108, 133)"
                                    : "rgba(0, 0, 0, 0.6)",
                                    textDecoration: "none",
                                    "&hover": {
                                        background: "gray"
                                    }
                                }}
                            >
                                {label}
                            </Link>
                        </MenuItem>
                    )
                })}
            </Menu>
            <img
                src={specRiteLogo}
                style={{
                    margin: '2px auto',
                }}
                height={34}
                width={32}
                alt="Spec-Rite Logo"
            />
        </Box>
    );
};
